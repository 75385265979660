<template>
  <v-container style="margin-bottom: 100px">
    <v-row class="sc-title mt-7" justify="space-between">
      <v-col cols="12" sm="6" md="8">
        <div>
          <h1>ADD IMAGES TO BID</h1>
        </div>
      </v-col>
      <v-col cols="12" md="2" sm="3">
        <div class="text-right">
          <v-btn
            color="primary"
            @click="
              $router.push({
                name: 'EnterBid',
                params: { id: $route.params.id },
              })
            "
            >Back
          </v-btn>
        </div>
      </v-col>
    </v-row>

    <customer-info />

    <v-dialog v-model="dialog" max-width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <div class="text-right">
          <v-btn color="primary" dark v-bind="attrs" v-on="on">
            Add Image
          </v-btn>
        </div>
      </template>

      <v-card>
        <div class="">
          <div class="popup-wrap">
            <v-btn class="close-popup" text @click.native="dialog = false">
              <v-img src="../../assets/close-icon.png" alt="close-icon"></v-img>
            </v-btn>

            <div class="popup-wrap-inner step-one">
              <div class="popup-title">
                <h6>Add Image to Bid</h6>
              </div>
              <div class="customer-form">
                <v-form ref="form" lazy-validation>
                  <v-container>
                    <v-row class="pr-10 pl-10">
                      <v-col cols="12" sm="12">
                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <div id="preview">
                              <img v-if="url" :src="url" />
                            </div>
                            <input
                              type="file"
                              ref="image"
                              accept="Image/*"
                              @change="onFileChange"
                            />
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12" class="">
                            <v-select
                              label="Type"
                              v-model="addImage.type"
                              :rules="[(v) => !!v || 'This field is required']"
                              :items="select_from_open_slots.items"
                            ></v-select>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12" class="pt-0 pb-0">
                            <v-textarea
                              label="Comments"
                              :rules="[(v) => !!v || 'This field is required']"
                              v-model="addImage.comments"
                              outlined
                            ></v-textarea>
                          </v-col>
                        </v-row>

                        <v-row>
                          <v-col cols="12" md="12">
                            <div class="text-right">
                              <v-btn color="primary" dark @click="saveFormData">
                                Add
                              </v-btn>
                            </div>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </div>
          </div>
        </div>
      </v-card>
    </v-dialog>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="select_from_open_slots.headers"
          :items="select_from_open_slots.data"
          class="elevation-1 cursor-pointer"
        >
          <template v-slot:item.image="{}">
            <img style="width: 50px" :src="url" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CustomerInfo from "./CustomerInfo";

export default {
  name: "AddImages.vue",
  components: { CustomerInfo },
  data() {
    return {
      valid: false,
      dialog: false,
      url: null,
      select_from_open_slots: {
        headers: [
          { text: "File", value: "image" },
          { text: "Comments", value: "comments" },
          { text: "Type", value: "type" },
        ],
        data: [],
        items: ["image1", "image2", "image3"],
      },
      addImage: {
        image: "",
        type: "",
        comments: "",
      },
    };
  },
  methods: {
    saveFormData() {
      let valid = this.$refs.form.validate();
      if (valid) {
        this.select_from_open_slots.data.push(this.addImage);
        this.dialog = false;
        this.addImage = {
          image: "",
          type: "",
          comments: "",
        };
      }
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.url = URL.createObjectURL(file);
      console.log(this.url);
    },
  },
};
</script>

<style scoped>
#preview {
  display: flex;
  justify-content: center;
  align-items: center;
}

#preview img {
  max-width: 70%;
  margin-bottom: 20px;
}
</style>
